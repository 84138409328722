<template>
	<BlockEcommerceProductList
		v-bind="{
			blockId,
			blockStyle,
			textColorVars,
			isProductListShown,
			productsPerPage,
			columnCount,
			productPages,
			productIds,
			productCategoryId,
			isButtonEnabled,
			buttonDisplay,
			buttonText,
			buttonStyle,
			buttonType,
			isLoading
		}"
		:products="productList"
		:translations="ecommerceTranslations"
		:is-eager="lcp.type === 'block-ecommerce-product-list' && lcp.id === blockId"
		@page-changed="handlePageChange"
		@button-click="handleButtonClick"
	/>
</template>

<script>
import {
	mapActions,
	mapState,
} from 'vuex';
import { defineComponent } from 'vue';

import BlockEcommerceProductList from '@zyro-inc/site-modules/components/blocks/ecommerce/BlockEcommerceProductList.vue';
import { useBlockEcommerceProductList } from '@zyro-inc/site-modules/components/blocks/ecommerce/useBlockEcommerceProductList';
import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants';
import { useEcommerceModal } from '@/components/ecommerce/modals/useEcommerceModal';

export default defineComponent({
	components: {
		BlockEcommerceProductList,
	},

	props: {
		blockId: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			default: () => ({}),
		},
		lcp: {
			type: Object,
			default: () => ({}),
		},
		ecommerceTranslations: {
			type: Object,
			default: () => ({}),
		},
		currentLocale: {
			type: String,
			default: SYSTEM_LOCALE,
		},
		blocks: {
			type: Object,
			default: () => ({}),
		},
	},

	setup(props) {
		const {
			openEcommerceModal,
			setProductPreviewData,
		} = useEcommerceModal(props);
		const {
			productList,
			productPages,
			blockStyle,
			textColorVars,
			isLoading,
			columnCount,
			productsPerPage,
			productIds,
			productCategoryId,
			isButtonEnabled,
			buttonDisplay,
			buttonText,
			buttonStyle,
			buttonType,
		} = useBlockEcommerceProductList(props);

		return {
			productList,
			productPages,
			blockStyle,
			textColorVars,
			isLoading,
			columnCount,
			productsPerPage,
			productIds,
			productCategoryId,
			openEcommerceModal,
			setProductPreviewData,
			isButtonEnabled,
			buttonDisplay,
			buttonText,
			buttonStyle,
			buttonType,
		};
	},

	computed: {
		...mapState(['website']),
		isProductListShown() {
			if (this.website.meta.defaultLocale && this.currentLocale !== this.website.meta.defaultLocale) {
				return false;
			}

			return !!this.productList?.length;
		},
	},

	methods: {
		...mapActions('ecommerce', ['getProducts']),
		handlePageChange(page) {
			const ids = this.productIds.slice(0, this.productsPerPage * page);

			this.getProducts(ids);
		},
		handleButtonClick(productPageData) {
			const ecommerceBlocks = Object.keys(this.blocks).filter((key) => this.blocks[key].type === 'BlockEcommerceProduct');
			const pageProductBlockId = ecommerceBlocks.find((block) => productPageData.blocks.includes(block));

			this.setProductPreviewData(this.blocks[pageProductBlockId]);
			this.openEcommerceModal('EcommerceProductPreview');
		},
	},

});
</script>
